@media all and (min-width: 480px) {
  .FileSubmission {
    padding: 20px 0;
  }

  .FileSubmission form,
  .response {
    margin: 0 auto;
    max-width: 400px;
  }

  .button-container {
    margin: 0 auto;
    max-width: 400px;
  }

  .button-container form {
    margin: 4px 0 4px 0;
  }

  .custom-file .custom-file-label {
    margin: 0;
  }

  .FileSubmission .form-group label,
  .FileSubmission .form-label label {
    margin-left: 5px;
  }
}
img {
  align-items: center;
  margin-bottom: 1em;
  max-width: 220px;
}

.orgNameError {
  margin-left: 1em;
  margin-top: 1em;
}

.response {
  margin-top: 2em;
}
