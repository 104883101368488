.wrap {
    min-height: 100vh;
    position: relative;
  }
  
  .app {
    display: flex;
    flex-direction: column;
    color: black;
    padding-bottom: 5%;
  }