.app-footer {
  bottom: 0;
  width: 100%;
  position: absolute;
  line-height: 25px;
  z-index: 2000;
  font-size: 0.9em;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
}