.statusModalFoo {
  color: #fff;
  background: #444;
  height: 40px;
  line-height: 40px; /* Same as height  */
}

.errorMessage {
  margin: 15px;
}

.errorMessage > h6 {
  display: inline;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  color: #000;
}

.errorMessage > p {
  display: inline;
  font-size: 16px;
  color: #000;
}

.StatusModal .btn {
  float: unset;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 15px;
}

.StatusModal .icon-box i {
  color: #f15e5e;
  font-size: 70px;
}

.StatusModal h4 {
  text-align: center;
  font-size: 26px;
  margin: auto;
}

.StatusModal .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.StatusModal {
  color: #636363;
  width: 600px;
}
.StatusModal .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
}
.StatusModal .modal-header {
  display: block;
  text-align: center;
  border-bottom: none;
  position: relative;
}
.StatusModal .required {
  color: red;
}

.StatusModal .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.StatusModal .modal-footer a {
  color: #999;
}

.text-center {
  width: 100%;
}
