@media all and (min-width: 480px) {
  .SwitchLabel {
    margin-top: 15px;
    display: block;
  }
  .switch {
    margin-top: 10px;
  }
  label {
    float: left;
    margin-left: 20px;
  }
  h4 {
    font-size: 32px;
    font-weight: bold;
    text-transform: capitalize;
    color: #223c89;
  }
  h5 {
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
    color: #223c89;
  }

  .tableLoading {
    font-size: 18px;
    font-weight: bold;
    color: #223c89;
  }

  .statusTable {
    font-size: 10.5px;
  }

  .orgNameError {
    margin-left: 1em;
    margin-top: 1em;
  }

  .css-lapokc {
    white-space: normal !important;
   }
}
