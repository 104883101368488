@media all and (min-width: 480px) {
  .signout {
    float: right;
    max-width: 200px;
    margin-right: 20px;
  }
}
img {
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 220px;
}
