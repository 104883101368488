.BulkAddOrgModal .btn {
  float: unset;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 15px;
}

.BulkAddOrgModal .icon-box i {
  color: #223c89;
  font-size: 70px;
}

.BulkAddOrgModal h4 {
  text-align: center;
  font-size: 26px;
  margin: auto;
}

.BulkAddOrgModal ul {
  margin: 1rem;
}

.BulkAddOrgModal .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.BulkAddOrgModal {
  color: #636363;
  width: 600px;
}
.BulkAddOrgModal .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}
.BulkAddOrgModal .modal-header {
  display: block;
  text-align: center;
  border-bottom: none;
  position: relative;
}
.BulkAddOrgModal .required {
  color: red;
}

.BulkAddOrgModal .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.BulkAddOrgModal .modal-footer a {
  color: #999;
}

.text-center {
  width: 100%;
}
