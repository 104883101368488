.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-icon {
  color: inherit !important;
}

.Organization button {
  color: #223c89;
  text-decoration: none;
}

.Organization button:hover {
  color: #5b8ec2;
  text-decoration: none;
}

.Organization button {
  display: flex;
  height: 100%;
  padding: 0 1rem 0 1rem;
  text-decoration: none;
}

.btn-link:focus {
  text-decoration: none;
}
